export const types = [
    {
        title: 'Migration',
        desc: 'Click here for migration or anything related to migration',
    },
    {
        title: 'Validation',
        desc: 'Click here to validate your wallet',
    },
    {
        title: 'Claim',
        desc: 'Click here to claim tokens or have issues claiming tokens',
    },
    {
        title: 'Swap',
        desc: 'Click here for token swap issues or any issues while swapping token',
    },
    {
        title: 'Slippage',
        desc: 'Click here for slippage related or transaction fee related issue',
    },
    {
        title: 'Connect to Dapps',
        desc: 'Click here to solve any dapp connection issue',
    },
    {
        title: 'Transaction',
        desc: 'Click here for all transaction related error',
    },
    {
        title: 'Claim Airdrop',
        desc: 'Click here for error during airdrop claim',
    },
    {
        title: 'Exchange',
        desc: 'Click here for token exchange or error while exchanging tokens',
    },
    {
        title: 'Cross Transfer',
        desc: 'Click here for cross bridge issues while doing cross transfer',
    },
    {
        title: 'Staking',
        desc: 'Click here for token stacking/unstake related issues',
    },
    {
        title: 'Login',
        desc: 'Click here for any error encounter while logging in to your wallet',
    },
    {
        title: 'Whitelist',
        desc: 'Click here to whitelist your address or whitelist related error',
    },
    {
        title: 'Buy Coins/Tokens',
        desc: 'To trade, your account must be marked as a trusted payment source',
    },
    {
        title: 'Locked Account',
        desc: 'Click here if your account was locked or wallet is stuck',
    },
    {
        title: 'NFTs',
        desc: 'Click here for NFTs minting/transfer issues',
    },
    {
        title: 'Missing/Irregular Balance',
        desc: 'Click here to recover your lost or missing funds',
    },
    {
        title: 'Wallet Glitch',
        desc: 'Click here if you have problem with trading wallet',
    },
    {
        title: 'Transaction Delay',
        desc: 'Click here for any transaction error or delayed transactions',
    },
    {
        title: 'Defi Farming',
        desc: 'Click here for defi farming / commercial farming issues',
    },
    
]

